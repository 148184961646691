import axios from "axios";

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/base`;
import helpers from "../../helpers/session";

export default {
  get: async (table, order = "ID") => {
    var data = JSON.stringify({
      table,
      order,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}`,
      data: data,
      headers: headers(),
    });

    return response.data;
  },
  getById: async (table, id) => {
    var data = JSON.stringify({
      table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/id/${id}`,
      data: data,
      headers: headers(),
    });

    return response.data;
  },
  insert: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/insert`,
      data: data,
      headers: headers(),
    });

    return response.data;
  },
  update: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/update`,
      data: data,
      headers: headers(),
    });

    return response.data;
  },
  remove: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/remove`,
      data: data,
      headers: headers(),
    });

    return response.data;
  },
};

const headers = () => {
  const user = helpers.getCurrentUser();
  const headersJson = {
    Authorization: "Basic bWluaXBkdi5saW5lYXNmdC5jb20uYnI6QXBwQDIwMjA=",
    "Content-Type": "application/json",
    "X-Tenant": user?.tenant,
    "X-User": user?.usuario,
  };

  return headersJson;
};
