import axios from "axios";

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/vendas`;
const baseProdutosVendaUrl = `${process.env.VUE_APP_API_BASE_URL}/produtos_vendas`;
import helpers from "../../helpers/session";
const user = helpers.getCurrentUser();
const headersJson = {
  Authorization: "Basic bWluaXBkdi5saW5lYXNmdC5jb20uYnI6QXBwQDIwMjA=",
  "Content-Type": "application/json",
  "X-Tenant": user?.tenant,
  "X-User": user?.usuario,
};
import baseApi from "../base/base.api";
export default {
  get: async (table, order, data_ini, data_fim) => {
    var data = JSON.stringify({
      table,
      order,
      data_ini,
      data_fim,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}`,
      data: data,
      headers: headers(),
    });

    return response.data;
  },
  getById: async (table, id) => {
    var data = JSON.stringify({
      table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/id/${id}`,
      data: data,
      headers: headers(),
    });

    return response.data;
  },
  insert: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/new`,
      data: data,
      headers: headers(),
    });

    sincronizarEstoque();
    return response.data;
  },
  vendaCatalogo: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/catalogo`,
      data: data,
      headers: headers(),
    });

    return response.data;
  },
  update: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/update`,
      data: data,
      headers: headers(),
    });
    sincronizarEstoque();
    return response.data;
  },
  remove: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/remove`,
      data: data,
      headers: headers(),
    });
    sincronizarEstoque();
    return response.data;
  },
  getDashboard: async (table, filter) => {
    const { data_ini, data_fim } = filter;
    var data = JSON.stringify({
      table,
      data_ini,
      data_fim,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/dashboard`,
      data: data,
      headers: headers(),
    });

    return response.data;
  },
  getByHash: async (table, id) => {
    var data = JSON.stringify({
      table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseProdutosVendaUrl}/hash/${id}`,
      data: data,
      headers: headers(),
    });

    return response.data;
  },
  getVendasProduto: async (table, produto) => {
    var data = JSON.stringify({
      table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseProdutosVendaUrl}/produto/${produto}`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  dashboard: async (table) => {
    var data = JSON.stringify({
      table: table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/dashboard`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  getByHash: async (table, hash) => {
    var data = JSON.stringify({
      table: table,
      hash: hash,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/hash`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
};

const sincronizarEstoque = async () => {
  localStorage.removeItem("produtos");
  await baseApi.get('produtos').then((response) => {
    localStorage.setItem("produtos", JSON.stringify(response.data));
  });
};

const headers = () => {
  const user = helpers.getCurrentUser();
  const headersJson = {
    Authorization: "Basic bWluaXBkdi5saW5lYXNmdC5jb20uYnI6QXBwQDIwMjA=",
    "Content-Type": "application/json",
    "X-Tenant": user?.tenant,
    "X-User": user?.usuario,
  };

  return headersJson;
};